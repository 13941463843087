
























































































import Vue from 'vue';
import {Language, languages} from "@/languages";
import {fonts, Font} from "@/fonts";
import axios from "axios";
export type DataType = {
  config:{
    fontSize:number,
    fontWeight:number,
    fontColor:string,
    fontBorderColor:string,
    fontShadowColor:string,
    shadow:boolean,
    fromLang:Language,
    toLangs:Array<Language>,
    textAlign:'left'|'center'|'right',
    font:Font,
    backGroundColor:string,
  }
  languages:Array<Language>,
  fonts:Array<Font>,
  noConfig:boolean,
  last:boolean

}


declare const webkitSpeechRecognition:any;
declare const SpeechRecognition:any;


export default Vue.extend({
  name: 'App',
  watch:{
    font(){
      this.fontLoad()
    }
  },
  computed: {
    customStyl() : any{
      return {
        fontSize:`${this.config.fontSize}px`,
        fontWeight:`${this.config.fontWeight}`,
        color:this.config.fontColor,
        textShadow:`${this.config.fontBorderColor} 2px 0px,  ${this.config.fontBorderColor} -2px 0px, ${this.config.fontBorderColor} 0px -2px, ${this.config.fontBorderColor} 0px 2px, ${this.config.fontBorderColor} 2px 2px , ${this.config.fontBorderColor} -2px 2px, ${this.config.fontBorderColor} 2px -2px, ${this.config.fontBorderColor} -2px -2px, ${this.config.fontBorderColor} 1px 2px,  ${this.config.fontBorderColor} -1px 2px, ${this.config.fontBorderColor} 1px -2px, ${this.config.fontBorderColor} -1px -2px, ${this.config.fontBorderColor} 2px 1px,  ${this.config.fontBorderColor} -2px 1px, ${this.config.fontBorderColor} 2px -1px, ${this.config.fontBorderColor} -2px -1px`+
          ( this.config.shadow ? `, 0 5px 5px ${this.config.fontShadowColor}` : ''),
        textAlign:this.config.textAlign,
        background:this.config.backGroundColor,
        fontFamily:`'${this.config.font.name}'`
      }
    }
  },
  components: {

  },
  data(): DataType{
    return {
      config: {
        fontSize:32,
        fontWeight:500,
        fontColor:'#000000',
        fontBorderColor:'#FFFFFF',
        fontShadowColor:'#000000',
        shadow:false,
        fromLang:languages[0],
        toLangs:[
          languages[0],
          languages[1],
        ],
        textAlign:"center",
        backGroundColor:'#00FF00',
        font:fonts[0],
      },
      languages,
      fonts:fonts,
      noConfig:true,
      last:false
    }
  },
  methods: {
    fontLoad() {
      const link = document.createElement('link') as HTMLLinkElement
      link.rel = 'stylesheet'
      link.href = this.config.font.link
      document.head.appendChild(link)
    },
    start():void {
      localStorage.setItem('config', JSON.stringify(this.config))
      window.open('#noConfig', 'transub', 'menubar=no,location=no,resizable=no,scrollbars=no,status=no')
    },
    async translate() {
      const response = await axios.post('/translate/', {
        from:this.config.fromLang.code,
        tos:this.config.toLangs.map(lang => lang.code),
        text:this.config.fromLang.text
      })
      Object.keys(response.data).map((key:string) => {
        this.config.toLangs.find((lang) => lang.code == key)!.text = response.data[key]
      })
    },
    play() {
      // eslint-ignore
      const speechRecognition = webkitSpeechRecognition || SpeechRecognition;
      const recognition = new speechRecognition();

      recognition.lang = this.config.fromLang.longCode;
      recognition.interimResults = true;
      recognition.continuous = true;
      const vm = this
      recognition.onresult = (event :any) => {
        console.log(event)
        const result = event.results[event.results.length-1]
        const script = result[result.length-1]
        let lastLength = vm.config.fromLang.text.length
        vm.config.fromLang.text = script.transcript
        if (result.isFinal || Math.abs(script.transcript.length - lastLength) >= 5) {
          vm.translate()
        }
      }
      recognition.onend = () => {
        recognition.start()
        vm.config.fromLang.text =''
      }

      recognition.start();
      recognition.onerror = (event:any) => {
        console.log({event})
      }

    }
  },
  mounted() {
    const localConfig = localStorage.getItem('config')
    if (localConfig) {
      this.config = JSON.parse(localConfig)
    }

    this.noConfig = !!location.href.match('noConfig')
    this.fontLoad()
    if (this.noConfig) {
      this.play()
    }

  }
});
