export const languages = [
  {
    name:'日本語',
    code:'JA',
    longCode:'ja-JP',
    sample:'こんにちは',
    text:''
  },
  {
    name:'英語',
    code:'EN',
    longCode:'en-US',
    sample:'Hello',
    text:''
  },
  {
    name:'中国語',
    code:'ZH',
    longCode:'zh-CN',
    sample:'你好',
    text:''
  }
] as Array<Language>
export type Language = {
  name:string,
  code:string,
  longCode:string,
  sample:string,
  text:string
}
