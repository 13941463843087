export const fonts = [
  {
    link:'https://fonts.googleapis.com/css?family=M+PLUS+1p',
    name:'M PLUS 1p'
  },
  {
    link:'https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c',
    name:'M PLUS Rounded 1c'
  },
  {
    link:'https://fonts.googleapis.com/earlyaccess/hannari.css',
    name:'Hannari'
  },
  {
    link:'https://fonts.googleapis.com/earlyaccess/kokoro.css',
    name:'Kokoro'
  },
  {
    link:'https://fonts.googleapis.com/css?family=Sawarabi+Mincho',
    name:'Sawarabi Mincho'
  },
  {
    link:'https://fonts.googleapis.com/earlyaccess/nikukyu.css',
    name:'Nikukyu'
  },
  {
    link:'https://fonts.googleapis.com/earlyaccess/nicomoji.css',
    name:'Nico Moji'
  },
  {
    link:'https://fonts.googleapis.com/css?family=Noto+Sans+JP',
    name:'Noto Sans JP'
  },

] as Array<{
  link:string,
  name:string
}>

export type Font = {
  link:string,
  name:string
}
